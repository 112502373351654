$(function () {
  var CTAButton = $('body > #CTA-teaser-container');
  if (CTAButton.length < 1) {
    return;
  }

  var controller = new ScrollMagic.Controller();

  var duration = Math.floor((window.innerHeight / 3));
  var offset = 100;

  new ScrollMagic.Scene({
      duration: duration,
      offset: offset,
    })
    .on('start', function (event) {
      if (event.scrollDirection === 'FORWARD') {
        CTAButton.css('display', 'block');
      } else if (event.scrollDirection === 'REVERSE') {
        CTAButton.css('display', 'none');
      }
    })
    .on('end', function (event) {
      if (event.scrollDirection === 'FORWARD') {
        CTAButton.css('pointer-events', 'all');
      } else if (event.scrollDirection === 'REVERSE') {
        CTAButton.css('pointer-events', 'none');
      }
    })
    .on('progress', function (event) {
      CTAButton.css('opacity', event.progress);
    })
    .addTo(controller);
});
