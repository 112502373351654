$(function () {
  if (!$('body').hasClass('obis-site-product-category')) {
    return;
  }

  var controlsWrapper = $('.product-slider').children('.slider-controls');
  var contentWrapper = $('.product-slider').children('.slider-contents');

  $('.product-slider').get(0)._slider = contentWrapper.slick({
    accessibility: !$('body').hasClass('neos-backend'),
    appendArrows: controlsWrapper,
    arrows: true,
    autoplay: !$('body').hasClass('neos-backend'),
    autoplaySpeed: 5000,
    // centerMode: true,
    // centerPadding: '0px',
    dots: false,
    draggable: !$('body').hasClass('neos-backend'),
    infinite: !$('body').hasClass('neos-backend'),
    nextArrow: controlsWrapper.children('.button-next'),
    pauseOnHover: true,
    prevArrow: controlsWrapper.children('.button-prev'),
    slidesToShow: 3,
    speed: 300,
    swipe: !$('body').hasClass('neos-backend'),
    swipeToSlide: true,
    touchMove: true,
    responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ],
  });
});