$(function () {
  if ($('body').hasClass('neos-backend') || !$('body').hasClass('obis-site-product-page')) {
    return;
  }

  var imageWrapper = $('section.obis-site-product .image-col');
  var textWrapper = $('section.obis-site-product .info-col');
  var image = imageWrapper.children('img');

  new Drift(image.get(0), {
    paneContainer: textWrapper.get(0),
    sourceAttribute: (isset(image.data('zoom-src'), 'string') ? 'data-zoom-src' : 'src'),
    zoomFactor: (isset(image.data('zoom-factor')) ? image.data('zoom-factor') : 3),
    inlinePane: true
  });
})