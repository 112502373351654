$(function() {
    function setSpinner(element) {
      $(element).html('<i class=" ajax-content fa fa-spinner fa-pulse"></i>');
    }
  
    function setNotice(element, gdprLink, contactLink, phoneLink, mailLink) {
      $(element).html('<p class="noconsent-notice"><b>Hinweis!</b><br>Wir können Ihnen das Kontaktformular aufgrund Ihrer aktuellen Cookie-Einstellungen leider nicht zur Verfügung stellen. Bitte nutzen Sie den direkten <a href="' + contactLink + '" target="_self">Kontakt</a> zu uns über ' + mailLink + ' oder ' + phoneLink + '. Weitere Informationen erhalten Sie in unserer <a href="' + gdprLink + '" target="_self">Datenschutzerklärung</a>.</p>');
    }
  
    if (!(isset(window._canInitAjaxForm, 'boolean') && window._canInitAjaxForm)) {
      var forms = $('div.ajax-form');
      var firstFormWrapper = forms.first().parent();
      var gdprLink = firstFormWrapper.data('gdpr-link');
      var contactLink = firstFormWrapper.data('contact-link');
  
      var firstInfoSlide = $('footer > .map-container .contact-box-wrapper .contact-information-slide').first();
      if (isset(firstInfoSlide)) {
        // We are on the homepage and can retrieve mail and phone from the Google Map overlay slider
        var phoneLink = firstInfoSlide.find('.phone > a').attr('href');
        var mailLink = firstInfoSlide.find('.mail > a').attr('href');
  
        if (isset(phoneLink, 'string')) {
          phoneLink = '<a href="' + phoneLink + '">Telefon</a>';
        } else {
          phoneLink = 'Telefon';
        }
  
        if (isset(mailLink, 'string')) {
          mailLink = '<a href="' + mailLink + '">E-Mail</a>';
        } else {
          mailLink = 'E-Mail';
        }
      } else {
        // We are not on the homepage as the Map is not available
        // We are probably on the contact page, so we can omit the links completely
        var phoneLink = 'Telefon';
        var mailLink = 'E-Mail';
      }
  
      setNotice($('div.ajax-form'), gdprLink, contactLink, phoneLink, mailLink);
  
      $(window).on('ajaxStart', function(event) {
        setSpinner('div.ajax-form')
      }).on('ajaxStop', function(event) {
        if (!(isset(window._canInitAjaxForm, 'boolean') && window._canInitAjaxForm)) {
          setNotice('div.ajax-form', gdprLink, contactLink, phoneLink, mailLink);
        }
      })
    }
  });
  